import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        config.baseURL = process.env.NODE_ENV === "test" ? this.jwtConfig.debugBasePath : this.jwtConfig.basePath
  
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      async error => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true // Add a flag to avoid infinite loop

          try {
            // Call the refreshToken method to get a new access token
            const newResponse = await this.refreshToken()
            // Update the Authorization header of the original request with the new token
            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${newResponse.data.accessToken}`
            // Retry the original request with the updated token
            return this.axiosIns(originalRequest)
          } catch (refreshError) {
            // If an error occurs during token renewal, redirect to the login page or handle the error appropriately
            localStorage.removeItem('userData')
            await router.push({ name: 'login' })
            return Promise.reject(refreshError)
          }
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
      username: userData.username,
    }).then(response => {
      // Actualizar el token y el token de refresco en el almacenamiento local
      this.setToken(response.data.accessToken)
      this.setRefreshToken(response.data.refreshToken)
      return response
    }).catch(error => {
      console.log(error)
    })
  }
}
